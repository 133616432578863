import { createStyles } from "@mantine/emotion";

export default createStyles ( ( {
	barHeight = "3px",
	barBgColor = "#ffffff40",
	filledBgColor = "#fff"
} ) => {

	return {
		barContainer: {
			position     : "relative",
			// width        : "70px",
			borderRadius : "1rem",
			// marginTop    : "0.5rem",
			marginBottom : "0.5rem"
			// marginRight  : "1rem"
		},
		baseBar: {
			position : "absolute",
			height   : "3px",
			width    : "100%",
			cursor   : "pointer",
			zIndex   : "10000",

			"&:hover": {
				"& .filledBar1": {
					"&::after": {
						opacity: "1"
					}
				}
			}
		},
		filledBar: {
			height          : barHeight,
			backgroundColor : filledBgColor,
			position        : "absolute",
			borderRadius    : `calc(${ barHeight } * 2)`,
			zIndex          : "1",
			// transition      : "width 1s linear",

			"&::after": {
				content      : "''",
				height       : `calc(${ barHeight } * 5)`,
				width        : `calc(${ barHeight } * 5)`,
				borderRadius : "100%",
				background   : filledBgColor,
				display      : "block",
				position     : "absolute",
				bottom       : "50%",
				transform    : "translate(100%,50%)",
				right        : `calc(${ barHeight } * 2)`,
				opacity      : "0",
				transition   : "opacity 0.1s linear"

			}

		// top             : "50%",
		// translate       : "translateY(-50%)"
		},
		rangeInput: {
			width        : "100%",
			appearance   : "none",
			background   : barBgColor,
			border       : "0px",
			outline      : "0px",
			cursor       : "pointer",
			boxSizing    : "border-box",
			borderRadius : `calc(${ barHeight } * 2)`,
			userSelect   : "none",
			touchAction  : "manipulation",
			display      : "block",
			height       : barHeight,
			margin       : "0px",
			padding      : "0px",
			position     : "absolute",
			zIndex       : "5",

			"&:hover": {
				"&::-webkit-slider-thumb": {
					opacity: "1"
				}
			},

			"&::-webkit-slider-thumb": {
				WebkitAppearance : "none",
				height           : `calc(${ barHeight } * 5)`,
				width            : `calc(${ barHeight } * 5)`,
				borderRadius     : "100%",
				background       : filledBgColor,
				opacity          : "0",
				transition       : "opacity 0.1s linear"
			}
		},
		slider: {
			".mantine-Slider-bar": {
				background : "white",
				opacity    : "1"
			},

			".mantine-Slider-track": {
				"&:before": {

					opacity: "0.38"
				}
			},

			"&:hover": {

				"& .mantine-Slider-thumb": {
					opacity    : "1",
					height     : `calc(${ barHeight } * 5)`,
					width      : `calc(${ barHeight } * 5)`,
					transition : "opacity 0.1s linear",
					border     : "none"
				}
			},

			"& .mantine-Slider-thumb": {
				opacity         : "0",
				backgroundColor : "white",
				height          : `calc(${ barHeight } * 5)`,
				width           : `calc(${ barHeight } * 5)`,
				transition      : "opacity 0.1s linear",
				border          : "none",
				"&:hover"       : {
					boxShadow: "none"
				},

				"&.Mui-active": {
					boxShadow: "none"
				}
			}
		},
		disableTransition: {
			"& .MuiSlider-thumb": {
				transition: "none"
			  },
			  "& .MuiSlider-track": {
				transition: "none"
			  }
		}
	};
} );