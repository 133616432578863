export const recordedConfig = {
	"pro": {
		"dynamicConfigModel": {
			"midRollEnabled"                    : false,
			"preRollEnabled"                    : false,
			"postRollEnabled"                   : false,
			"preRollCount"                      : 1,
			"midRollCount"                      : 1,
			"postRollCount"                     : 1,
			"recordedStreamAdInsertionInterval" : 5,
			"preRollUris"                       : [
				"https://pubads.g.doubleclick.net/gampad/live/ads?iu=/22991961619/replay_preroll_web&description_url=http%3A%2F%2Fwww.rooter.gg%2F&tfcd=0&npa=0&sz=400x300%7C640x480&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=",
				"https://pubads.g.doubleclick.net/gampad/live/ads?iu=/22991961619/replay_preroll_mweb&description_url=http%3A%2F%2Fwww.rooter.gg%2F&tfcd=0&npa=0&sz=400x300%7C640x480&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator="
			],
			"midRollUris": [
				"https://pubads.g.doubleclick.net/gampad/live/ads?iu=/22991961619/replay_midroll_web&description_url=http%3A%2F%2Fwww.rooter.gg%2F&tfcd=0&npa=0&sz=400x300%7C640x480&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=",
				"https://pubads.g.doubleclick.net/gampad/live/ads?iu=/22991961619/replay_midroll_mweb&description_url=http%3A%2F%2Fwww.rooter.gg%2F&tfcd=0&npa=0&sz=400x300%7C640x480&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator="
			],
			"postRollUris": []
		}
	},
	"standard": {
		"dynamicConfigModel": {
			"midRollEnabled"                    : true,
			"preRollEnabled"                    : true,
			"postRollEnabled"                   : false,
			"preRollCount"                      : 2,
			"midRollCount"                      : 1,
			"postRollCount"                     : 2,
			"recordedStreamAdInsertionInterval" : 5,
			"preRollUris"                       : [
				"https://pubads.g.doubleclick.net/gampad/live/ads?iu=/22991961619/replay_preroll_web&description_url=http%3A%2F%2Fwww.rooter.gg%2F&tfcd=0&npa=0&sz=400x300%7C640x480&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=",
				"https://pubads.g.doubleclick.net/gampad/live/ads?iu=/22991961619/replay_preroll_mweb&description_url=http%3A%2F%2Fwww.rooter.gg%2F&tfcd=0&npa=0&sz=400x300%7C640x480&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator="
			],
			"midRollUris": [
				"https://pubads.g.doubleclick.net/gampad/live/ads?iu=/22991961619/replay_midroll_web&description_url=http%3A%2F%2Fwww.rooter.gg%2F&tfcd=0&npa=0&sz=400x300%7C640x480&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=",
				"https://pubads.g.doubleclick.net/gampad/live/ads?iu=/22991961619/replay_midroll_mweb&description_url=http%3A%2F%2Fwww.rooter.gg%2F&tfcd=0&npa=0&sz=400x300%7C640x480&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator="
			],
			"postRollUris": []
		}
	}
};

export const videoConfig = {
	"pro": {
		"dynamicConfigModel": {
			"midRollEnabled"  : false,
			"preRollEnabled"  : false,
			"postRollEnabled" : false,
			"preRollCount"    : 1,
			"midRollCount"    : 1,
			"postRollCount"   : 1,
			"midRollConfig"   : [
				{
					"min"   : 0,
					"max"   : 3,
					"count" : 1
				},
				{
					"min"   : 3,
					"max"   : 6,
					"count" : 2
				},
				{
					"min"   : 6,
					"max"   : 10,
					"count" : 3
				},
				{
					"min"   : 10,
					"max"   : 15,
					"count" : 4
				},
				{
					"min"   : 15,
					"max"   : 20,
					"count" : 5
				},
				{
					"min"   : 20,
					"max"   : 26,
					"count" : 6
				},
				{
					"min"   : 26,
					"max"   : 35,
					"count" : 7
				},
				{
					"min"   : 35,
					"max"   : 42,
					"count" : 8
				},
				{
					"min"   : 42,
					"max"   : 50,
					"count" : 9
				},
				{
					"min"   : 50,
					"max"   : 63,
					"count" : 10
				},
				{
					"min"   : 63,
					"max"   : 80,
					"count" : 11
				},
				{
					"min"   : 80,
					"max"   : 95,
					"count" : 12
				},
				{
					"min"   : 95,
					"max"   : 120,
					"count" : 13
				},
				{
					"min"   : 120,
					"max"   : 150,
					"count" : 14
				},
				{
					"min"   : 150,
					"max"   : 180,
					"count" : 15
				},
				{
					"min"   : 180,
					"max"   : 220,
					"count" : 16
				},
				{
					"min"   : 220,
					"max"   : 300,
					"count" : 17
				},
				{
					"min"   : 300,
					"max"   : 500,
					"count" : 18
				},
				{
					"min"   : 500,
					"max"   : 1000,
					"count" : 19
				},
				{
					"min"   : 1000,
					"max"   : 10000,
					"count" : 20
				}
			],
			"preRollUris": [
				"https://pubads.g.doubleclick.net/gampad/live/ads?iu=/22991961619/video_preroll_web&description_url=http%3A%2F%2Fwww.rooter.gg%2F&tfcd=0&npa=0&sz=400x300%7C640x480&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=",
				"https://pubads.g.doubleclick.net/gampad/live/ads?iu=/22991961619/video_preroll_mweb&description_url=http%3A%2F%2Fwww.rooter.gg%2F&tfcd=0&npa=0&sz=400x300%7C640x480&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator="
			],
			"midRollUris": [
				"https://pubads.g.doubleclick.net/gampad/live/ads?iu=/22991961619/video_midroll_web&description_url=http%3A%2F%2Fwww.rooter.gg%2F&tfcd=0&npa=0&sz=400x300%7C640x480&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=",
				"https://pubads.g.doubleclick.net/gampad/live/ads?iu=/22991961619/video_midroll_mweb&description_url=http%3A%2F%2Fwww.rooter.gg%2F&tfcd=0&npa=0&sz=400x300%7C640x480&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator="
			],
			"postRollUris": []
		}
	},
	"standard": {
		"dynamicConfigModel": {
			"midRollEnabled"  : true,
			"preRollEnabled"  : true,
			"postRollEnabled" : false,
			"preRollCount"    : 2,
			"midRollCount"    : 2,
			"postRollCount"   : 2,
			"midRollConfig"   : [
				{
					"min"   : 0,
					"max"   : 3,
					"count" : 1
				},
				{
					"min"   : 3,
					"max"   : 6,
					"count" : 2
				},
				{
					"min"   : 6,
					"max"   : 10,
					"count" : 3
				},
				{
					"min"   : 10,
					"max"   : 15,
					"count" : 4
				},
				{
					"min"   : 15,
					"max"   : 20,
					"count" : 5
				},
				{
					"min"   : 20,
					"max"   : 26,
					"count" : 6
				},
				{
					"min"   : 26,
					"max"   : 35,
					"count" : 7
				},
				{
					"min"   : 35,
					"max"   : 42,
					"count" : 8
				},
				{
					"min"   : 42,
					"max"   : 50,
					"count" : 9
				},
				{
					"min"   : 50,
					"max"   : 63,
					"count" : 10
				},
				{
					"min"   : 63,
					"max"   : 80,
					"count" : 11
				},
				{
					"min"   : 80,
					"max"   : 95,
					"count" : 12
				},
				{
					"min"   : 95,
					"max"   : 120,
					"count" : 13
				},
				{
					"min"   : 120,
					"max"   : 150,
					"count" : 14
				},
				{
					"min"   : 150,
					"max"   : 180,
					"count" : 15
				},
				{
					"min"   : 180,
					"max"   : 220,
					"count" : 16
				},
				{
					"min"   : 220,
					"max"   : 300,
					"count" : 17
				},
				{
					"min"   : 300,
					"max"   : 500,
					"count" : 18
				},
				{
					"min"   : 500,
					"max"   : 1000,
					"count" : 19
				},
				{
					"min"   : 1000,
					"max"   : 10000,
					"count" : 20
				}
			],
			"preRollUris": [
				"https://pubads.g.doubleclick.net/gampad/live/ads?iu=/22991961619/video_preroll_web&description_url=http%3A%2F%2Fwww.rooter.gg%2F&tfcd=0&npa=0&sz=400x300%7C640x480&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=",
				"https://pubads.g.doubleclick.net/gampad/live/ads?iu=/22991961619/video_preroll_mweb&description_url=http%3A%2F%2Fwww.rooter.gg%2F&tfcd=0&npa=0&sz=400x300%7C640x480&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator="
			],
			"midRollUris": [
				"https://pubads.g.doubleclick.net/gampad/live/ads?iu=/22991961619/video_midroll_web&description_url=http%3A%2F%2Fwww.rooter.gg%2F&tfcd=0&npa=0&sz=400x300%7C640x480&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=",
				"https://pubads.g.doubleclick.net/gampad/live/ads?iu=/22991961619/video_midroll_mweb&description_url=http%3A%2F%2Fwww.rooter.gg%2F&tfcd=0&npa=0&sz=400x300%7C640x480&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator="
			],
			"postRollUris": []
		}
	}
};

export const liveConfig = {
	"pro": {
		"dynamicConfigModel": {
			"preRollEnabled" : false,
			"preRollCount"   : 1,
			"preRollUris"    : [
				"https://pubads.g.doubleclick.net/gampad/live/ads?iu=/22991961619/live_preroll_web&description_url=http%3A%2F%2Fwww.rooter.gg%2F&tfcd=0&npa=0&sz=400x300%7C640x480&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=",
				"https://pubads.g.doubleclick.net/gampad/live/ads?iu=/22991961619/live_preroll_mweb&description_url=http%3A%2F%2Fwww.rooter.gg%2F&tfcd=0&npa=0&sz=400x300%7C640x480&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator="
			]
		}
	},
	"standard": {
		"dynamicConfigModel": {
			"preRollEnabled" : true,
			"preRollCount"   : 2,
			"preRollUris"    : [
				"https://pubads.g.doubleclick.net/gampad/live/ads?iu=/22991961619/live_preroll_web&description_url=http%3A%2F%2Fwww.rooter.gg%2F&tfcd=0&npa=0&sz=400x300%7C640x480&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=",
				"https://pubads.g.doubleclick.net/gampad/live/ads?iu=/22991961619/live_preroll_mweb&description_url=http%3A%2F%2Fwww.rooter.gg%2F&tfcd=0&npa=0&sz=400x300%7C640x480&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator="
			]
		}
	}
};
