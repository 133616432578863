import { checkIsBrowser } from "_common/utils";

export const shortenNumber = value => {
	let newValue = value;

	if ( value >= 1000000 ) newValue = parseFloat ( ( value / 1000000 ).toFixed ( 1 ) ) + "M";
	else if ( value >= 1000 ) {
		newValue = parseFloat ( ( value / 1000 ).toFixed ( 1 ) ) + "K";
	}

	return newValue;
};

export const formatTime = ( timeInSeconds = 0 ) => {
	let seconds = Math.floor ( timeInSeconds % 60 );
	let minutes = Math.floor ( ( timeInSeconds / 60 ) % 60 );
	let hours   = Math.floor ( timeInSeconds / ( 60 * 60 ) );

	seconds = seconds.toString ().padStart ( 2, "0" );
	if ( timeInSeconds >= 600 ) minutes = minutes.toString ().padStart ( 2, "0" );
	if ( timeInSeconds >= 36000 ) hours = hours.toString ().padStart ( 2, "0" );

	return timeInSeconds >= 3600
		? `${ hours }:${ minutes }:${ seconds }`
		: `${ minutes }:${ seconds }`;
};

export const formattedVideoDuration = seconds => {
	try {
		if ( !seconds ) return "00:00";

		if ( seconds < 60 * 60 )
			return new Date ( seconds * 1000 ).toISOString ().slice ( 14, 19 );

		return new Date ( seconds * 1000 ).toISOString ().slice ( 11, 19 );
	} catch ( error ) {
		return "";
	}
};

export const bound = ( value, min = null, max = null ) => {
	let boundedValue = value;

	if ( min !== null ) boundedValue = Math.max ( min, value );
	if ( max !== null ) boundedValue = Math.min ( max, boundedValue );

	return boundedValue;
};

export const isiOS = () => {
	if ( !checkIsBrowser () )
		return false;

	return [
		"iPad Simulator",
		"iPhone Simulator",
		"iPod Simulator",
		"iPad",
		"iPhone",
		"iPod"
	].includes ( navigator.platform ) ||
    ( navigator.userAgent.includes ( "Mac" ) && "ontouchend" in document );
};

/* export function checkForAppleDevice () {
  if ( !checkIsBrowser () ) return;
  const isSafari = /^((?!chrome|android).)*safari/i.test ( navigator.userAgent );
  const iOS      = ( /iPad|iPhone|iPod/.test ( navigator.userAgent ) && !window.MSStream ) || isSafari;

  return iOS;
}
 */
