import { createStyles } from "@mantine/emotion";

export default createStyles ( ( theme, props ) => {

	return {
		containerClass: {
			width       : props?.show ? "70px" : "0px",
			transition  : "width 0.2s linear",
			visibility  : props?.show ? "visible" : "hidden",
			marginRight : props?.show ? "1.5rem" : "1rem",
			marginLeft  : props?.show ? "0.5rem" : "0"
		}
	};
} );