import { createStyles }            from "@mantine/emotion";
import { useInterval, useTimeout } from "@mantine/hooks";
import dynamic                     from "next/dynamic";
import { useRouter }               from "next/router";
import { useState, useEffect }     from "react";

import { useGetAdConfig }   from "_common/hooks/global";
import { useGetDeviceType } from "_common/hooks/global";
import { useGetDarkMode }   from "_common/hooks/global";
import { isPageRoute }      from "_common/utils";

const AdSlot = dynamic ( () => import ( "_components/features/ads/slot" ) );

// in player ad

const component = () => {
	const deviceType                                = useGetDeviceType ();
	const isMobile                                  = deviceType === "mobile";
	const { LIVE_INSCREEN_WEB, VIDEO_INSCREEN_WEB } = useGetAdConfig ();

	const router       = useRouter ();
	const isVideoPage  = isPageRoute ( { router, page: "video" } );
	const isStreamPage = isPageRoute ( { router, page: "stream" } );

	if ( isMobile )
		return null;

	if ( isStreamPage )
		return <Ad config = { LIVE_INSCREEN_WEB } />;

	if ( isVideoPage )
		return <Ad config = { VIDEO_INSCREEN_WEB } />;

	return null;
};

const CloseBtn = ( {
	showBtn, seconds,
	onClose, showClose
} ) => {
	const { classes } = useStyles ( null, { name: "features/ads" } );

	if ( !showBtn )
		return null;

	if ( showClose ) {
		return (
			<div
				className = { classes.closeContainer }
				onClick   = { onClose }
			>
				<span className = { classes.closeText }>
					{"Close"}
				</span>
			</div>
		);
	}

	return (
		<div className = { classes.closeContainer }>
			<span className = { classes.closeText }>
				{`Close in ${ seconds / 1000 }`}
			</span>
		</div>
	);
};

const Ad = ( { config } ) => {
	const darkMode                      = useGetDarkMode ();
	const { classes }                   = useStyles ( null, { name: "features/ads" } );
	const closeTimer                    = config?.closeTime * 1000;// 3000;
	const adTimer                       = config?.repeatTime * 1000; // 60000;
	const [ ready, setReady ]           = useState ( false );
	const [ seconds, setSeconds ]       = useState ();
	const [ showAd, setShowAd ]         = useState ( true );
	const [ showClose, setShowClose ]   = useState ( false );
	const interval                      = useInterval ( () => setSeconds ( s => s - 1000 ), 1000 );
	const { start: startCloseBtnTimer } = useTimeout ( () => {
		setShowClose ( true );
	}, closeTimer, { autoInvoke: false } );

	const { start: startAdTimer } = useTimeout ( () => {
		setShowAd ( true );
		startCloseBtnTimer ();
		interval.start ();
	}, adTimer );

	useEffect ( () => {
		if ( !closeTimer )
			return;

		startCloseBtnTimer ();
		interval.start ();

		setSeconds ( closeTimer );

		return interval.stop;
	}, [ closeTimer ] );

	useEffect ( () => {
		if ( !showClose )
			return;

		interval.stop ();
	}, [ showClose ] );

	if ( !config?.enabled )
		return null;

	const path = darkMode ? config?.slotId?.dark : config?.slotId?.light;

	const adRendered = () => {
		setReady ( true );
	};

	const onClose = () => {
		setSeconds ( closeTimer );
		setShowClose ( false );
		setShowAd ( false );
		startAdTimer ();
	};

	return (
		<div className = { classes.container }
			id            = "in-player"
		>
			<div className = { classes.container2 }>

				<CloseBtn
					onClose   = { onClose }
					seconds   = { seconds }
					showBtn   = { showAd && ready }
					showClose = { showClose }
				/>

				{showAd ? (
					<AdSlot
						adRendered = { adRendered }
						id         = { "gpt-passback" }
						index      = { "in-player" }
						path       = { path }
						size       = { config?.size }
					/>
				) : null}
			</div>
		</div>
	);
};

export default component;

const useStyles = createStyles ( () => {
	return {
		container: {
			width          : "100%",
			position       : "absolute",
			bottom         : "75px",
			zIndex         : 9,
			display        : "flex",
			justifyContent : "center"
		},
		container2: {
			display        : "flex",
			justifyContent : "center",
			flexDirection  : "column"
		},
		closeContainer: {
			width     : "100%",
			textAlign : "right"
		}
	};
} );
