import Presentation from "./presentation";

const CustomSlider = ( {
	onChange,
	value,
	containerClass,
	// rangeInputClass,
	// filledBarClass,
	max,
	min,
	percent,
	step,
	onMouseEnter,
	onMouseLeave,
	stopAnimation,
	disableAmination
} ) => {
	return (
		<Presentation
			containerClass   = { containerClass }
			disableAmination = { disableAmination }
			// filledBarClass   = { filledBarClass }
			max              = { max }
			min              = { min }
			onChange         = { onChange }
			onMouseEnter     = { onMouseEnter }
			onMouseLeave     = { onMouseLeave }
			percent          = { percent }
			// rangeInputClass  = { rangeInputClass }
			step             = { step }
			stopAnimation    = { stopAnimation }
			value            = { value }

		/>
	);
};

export default CustomSlider;