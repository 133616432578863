import { createStyles } from "@mantine/emotion";

import { rooterMediaQuery } from "_styles/theme/mediaQuery";

export default createStyles ( ( theme, props ) => {

	return {
		container: {
			border          : "none",
			borderRadius    : "10px",
			width           : "300px !important",
			maxWidth        : 360,
			backgroundColor : theme.other.darkMode ? theme.other.paletteNew.darkModeInclinedBlack2 : theme.other.paletteNew.secondaryWhite,
			color           : theme.other.colors.text.primary,
			padding         : "10px 0px",
			fontSize        : "1.2rem"

		},
		item: {
			color     : theme.other.colors.text.primary,
			fontSize  : theme.fontSizes.sm,
			padding   : "0 2rem",
			minHeight : "4rem",

			"&[data-hovered]": {
				backgroundColor : "none",
				background      : "none"
			},
			"&[data-selected]": {
				backgroundColor: theme.other.colors.selectedState.primary
			}
		},
		caretIcon: {
			height : "3rem",
			width  : "3rem"
		},
		tooltip: {
			fontSize: "1.2rem"
		},
		divider: {
			borderColor: theme.other.colors.border.primary
		},
		reelMuteIcon: {
			width        : "3.2rem",
			borderRadius : "1rem",
			position     : "absolute",
			right        : "1rem",
			top          : "1.5rem",
			cursor       : "pointer",
			border       : "none",
			zIndex       : "20",

			[rooterMediaQuery.mobile]: {
				right: "1.5rem"
			}
		},
		playPauseIconWrapper: {
			opacity      : "0.9",
			borderRadius : "1rem",
			position     : "absolute",
			top          : "53%",
			width        : "fit-content",
			left         : "50%",
			transform    : "translate(-50%,-50%)",
			right        : "2rem",
			zIndex       : "12"
		},
		icon: {
			width      : "6rem",
			bottom     : "0",
			left       : "-3rem",
			position   : "absolute",
			transition : "opacity 2s ease-in-out",
			opacity    : 0,

			"&[data-show]": {
				animation: "zoomOutFade 0.4s linear"
			},

			[rooterMediaQuery.mobile]: {
				right: "7rem"
			},

			"@keyframes zoomOutFade": {
				"0%": {
					transform : "scale(0.7)",
					opacity   : 1
				},
				"100%": {
					transform : "scale(1.2)",
					opacity   : 1
				}
			}
		},
		sessionStatsContainer: {
			display    : "flex",
			alignItems : "center",
			gap        : "1rem",
			marginLeft : "1rem"
		},
		liveTagContainer: {
			backgroundColor : theme.other.paletteNew.tertiaryPink,
			padding         : "0.2rem 0.8rem",
			borderRadius    : props?.startedAt ? "0.5rem 0 0 0.5rem" : "0.3rem",
			display         : "flex",
			zIndex          : 1,
			alignItems      : "center"
		},
		liveTagText: {
			fontSize   : "1.2rem",
			fontWeight : "500",
			color      : theme.other.paletteNew.secondaryWhite
		},
		liveTimeText: {
			// display        : "flex",
			// alignItems     : "center",
			// justifyContent : "center",
			textAlign  : "center",
			fontSize   : "1.2rem",
			fontWeight : "500",
			minWidth   : "3rem",
			color      : theme.other.paletteNew.secondaryWhite
		},
		viewTagText: {
			fontSize   : "1.2rem",
			fontWeight : "500",
			color      : theme.other.paletteNew.secondaryWhite
		},
		liveTimeContainer: {
			display      : "flex",
			gap          : "0.5rem",
			alignItems   : "center",
			padding      : "0.2rem 0.8rem",
			borderRadius : "0 0.5rem 0.5rem 0",
			marginLeft   : "-10px",
			background   : theme.other.paletteNew.secondaryBlack50
		},
		viewTagContainer: {
			display      : "flex",
			gap          : "0.5rem",
			alignItems   : "center",
			padding      : "0.2rem 0.8rem",
			borderRadius : "0.5rem",
			background   : theme.other.paletteNew.secondaryBlack50
		},
		eyeIcon: {
			height  : "1rem",
			padding : "1px 0"
		},
		itemRightSection: {
			display        : "flex",
			alignItems     : "center",
			justifyContent : "center",
			flexDirection  : "column"
		}
	};
} );
