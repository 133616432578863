import {
	useCallback,
	useEffect,
	useMemo,
	useRef
} from "react";

import {
	useGetActivePlayerType,
	useGetLiveRef,
	useGetSeekEnabled,
	useGetVodRef,
	useSetActivePlayerType,
	useSetFullscreen
} from "../states";
import { isiOS } from "../utils";

import usePlayer from "./usePlayer";

const LIVE = "live";

const PlaybackProvider = () => {
	const liveRef             = useGetLiveRef ();
	const vodRef              = useGetVodRef ();
	const setActivePlayerType = useSetActivePlayerType ();
	const activePlayerType    = useGetActivePlayerType ();
	const setFullscreen       = useSetFullscreen ();
	const isVodAvailable      = useGetSeekEnabled ();
	const isIOS               = isiOS ();
	const livePlayer          = usePlayer ();
	const vodPlayer           = usePlayer ();
	const recordingStartTime  = null;
	const activePlayer        = useRef ();
	const inActivePlayer      = useRef ();

	// const isLiveAvailable = useMemo (
	// () => isChannelLive && !livePlayer.error,
	// [ isChannelLive, livePlayer.error ]
	// );
	// const isVodAvailable = useMemo (
	// () => vodPlayer.isReady && !vodPlayer.error,
	// [ vodPlayer.error, vodPlayer.isReady ]
	// );

	useEffect ( () => {
		if ( !liveRef?.current )
			return;

		livePlayer.setRef ( liveRef.current );
	}, [ liveRef?.current ] );

	useEffect ( () => {
		if ( !vodRef?.current )
			return;

		vodPlayer.setRef ( vodRef.current );
	}, [ vodRef?.current ] );

	useEffect ( () => {
		if ( activePlayerType === "live" ) {
			activePlayer.current = livePlayer;

			if ( isVodAvailable )
				inActivePlayer.current = vodPlayer;
		} else {
			activePlayer.current   = vodPlayer;
			inActivePlayer.current = livePlayer;
		}

	}, [ activePlayerType, isVodAvailable ] );

	// useEffect ( () => {
	// if ( livePlayer?.isLoading || !vodPlaybackURL || vodPlayerURL )
	// return;

	/*
     * Waiting for live stream to load before calling vod playlist
     * this will reduce the initial loading time
     */
	// setVodPlayerURL ( vodPlaybackURL );
	// }, [ livePlayer.isLoading ] );

	const getVodDuration = useCallback ( () => {
		if ( !vodPlayer?.isReady () ) return 0;

		return vodPlayer.getDuration ();
	}, [ vodPlayer?.isReady () ] );

	const seekVodToPos = useCallback (
		seekPosition => {
			if ( !vodPlayer?.isReady () )
				return;

			vodPlayer?.seekToPos ( seekPosition );
		},
		[ vodPlayer?.isReady () ]
	);

	const getVodPosition = useCallback (
		() => {
			if ( !vodPlayer?.isReady () ) return 0;

			return vodPlayer?.getPosition ();
		},
		[ vodPlayer?.isReady () ]
	);

	// Ensures we restart the playback when switching player
	const setActivePlayerTypeWithState = useCallback (
		nextType => {
			setActivePlayerType ( prevType => {
				const nextPlayFn = nextType === LIVE ? livePlayer.play : vodPlayer.play;

				if ( nextType !== prevType ) {
					nextPlayFn ();
				}

				return nextType;
			} );
		},
		[ livePlayer.play, vodPlayer.play ]
	);

	const togglePlayback = useCallback (
		() => {
			if ( activePlayer?.current )
				activePlayer.current.togglePlayback ();
		},
		[ activePlayer?.current ]
	);

	const toggleMute = useCallback (
		() => {
			if ( activePlayer?.current )
				activePlayer.current.toggleMute ();

			if ( inActivePlayer?.current )
				inActivePlayer.current.toggleMute ();
		},
		[ activePlayer?.current, inActivePlayer?.current ]
	);

	const setQuality = useCallback (
		val => {
			if ( activePlayer?.current )
				activePlayer.current.setQuality ( val );

			if ( inActivePlayer?.current )
				inActivePlayer.current.setQuality ( val );
		},
		[ activePlayer?.current, inActivePlayer?.current ]
	);

	const setQualityOptions = useCallback (
		() => {
			if ( activePlayer?.current )
				activePlayer.current.setQualityOptions ();
		},
		[ activePlayer?.current ]
	);

	const toggleFullscreenIOS = useCallback ( () => {
		if ( !livePlayer?.isReady () )
			return;

		if ( !document.fullscreenElement ) {
			livePlayer.enterFullscreen ();
			setFullscreen ( true );
		} else {
			livePlayer.exitFullscreen ();
			setFullscreen ( false );
		}

	}, [ livePlayer?.isReady () ] );

	const toggleFullscreen = () => {
		if ( isIOS )
			return toggleFullscreenIOS ();

		let elem = document.getElementById ( "complete-video-player" );

		if ( !document.fullscreenElement ) {
			elem.requestFullscreen ().catch ( err => {
				alert ( `Error attempting to enable fullscreen mode: ${ err.message } (${ err.name })` );
			} );
			setFullscreen ( true );
		} else {
			document.exitFullscreen ();
			setFullscreen ( false );
		}
	};

	const onVolumeChange = useCallback (
		val => {
			if ( activePlayer?.current )
				activePlayer.current.onVolumeChange ( val );

			if ( inActivePlayer?.current )
				inActivePlayer.current.onVolumeChange ( val );
		},
		[ activePlayer?.current, inActivePlayer?.current ]
	);

	const setPlaybackSpeedOnVideo = useCallback (
		val => {
			if ( livePlayer?.isReady () )
				livePlayer.setPlaybackSpeed ( val );
		},
		[ livePlayer?.isReady () ]
	);

	const setPlaybackSpeed = useCallback (
		val => {
			if ( vodRef?.current )
				vodPlayer.setPlaybackSpeed ( val );
		},
		[ vodRef?.current ]
	);

	const onSeek = useCallback (
		val => {
			if ( !vodPlayer?.isReady () )
				return;

			vodPlayer.onSeek ( val );
		},
		[ vodPlayer?.isReady () ]
	);

	const onSeekVideo = useCallback (
		val => {
			if ( !livePlayer?.isReady () )
				return;

			livePlayer.onSeek ( val );
		},
		[ livePlayer?.isReady () ]
	);

	const seekByNSeconds = useCallback (
		val => {
			if ( !vodPlayer?.isReady () )
				return;

			vodPlayer.seekByNSeconds ( val );
		},
		[ vodPlayer?.isReady () ]
	);

	const seekByNSecondsOthers = useCallback (
		val => {
			if ( !livePlayer?.isReady () )
				return;

			livePlayer.seekByNSeconds ( val );
		},
		[ livePlayer?.isReady () ]
	);

	// Switch to the LIVE player if VOD has ended (will lead to "live stream offline" error state)
	// useEffect ( () => {
	// if ( vodPlayer.hasEnded ) setActivePlayerType ( LIVE );
	// }, [ vodPlayer.hasEnded ] );

	// Reset the VOD player if the LIVE player has encountered an error and is currently active (ensures that the VOD player starts fresh with the next live stream)
	useEffect ( () => {
		if ( activePlayerType === LIVE && livePlayer.error ) {
			const resetVOD = vodPlayer.reset;

			resetVOD ();
		}
	}, [ activePlayerType, vodPlayer.reset, livePlayer.error ] );

	// Seek the VOD player to the end while the LIVE player is active so it can continue fetching new playlists
	useEffect ( () => {
		if ( isVodAvailable && activePlayerType === LIVE ) {
			const vodDuration = getVodDuration ();

			seekVodToPos ( vodDuration ); // Seek VOD to the end to fetch new playlists while LIVE is active
		}
	}, [ activePlayerType, getVodDuration, isVodAvailable, seekVodToPos ] );

	// Pausing inactive player
	useEffect ( () => {
		if ( !vodRef?.current || !liveRef?.current )
			return;

		const inactivePlayerInstance = activePlayerType === LIVE ? vodRef?.current : liveRef?.current;

		inactivePlayerInstance.pause ();
	}, [ activePlayerType, liveRef?.current, vodRef?.current ] );

	return useMemo (
		() => ( {
			togglePlayback,
			toggleMute,
			toggleFullscreen,
			onVolumeChange,
			getVodDuration,
			getVodPosition,
			setPlaybackSpeed,
			setPlaybackSpeedOnVideo,
			onSeek,
			onSeekVideo,
			seekByNSeconds,
			seekByNSecondsOthers,
			// isLiveAvailable,
			recordingStartTime,
			setQualityOptions,
			setQuality,
			resetVOD            : vodPlayer.reset,
			seekVodToPos,
			setActivePlayerType : setActivePlayerTypeWithState,
			vodPlayerInstance   : vodRef?.current
		} ),
		[
			togglePlayback,
			toggleMute,
			toggleFullscreen,
			onVolumeChange,
			setQuality,
			setQualityOptions,
			getVodDuration,
			getVodPosition,
			setPlaybackSpeed,
			setPlaybackSpeedOnVideo,
			onSeek,
			onSeekVideo,
			seekByNSeconds,
			seekByNSecondsOthers,
			// isLiveAvailable,
			livePlayer,
			recordingStartTime,
			seekVodToPos,
			setActivePlayerTypeWithState,
			vodPlayer,
			vodRef?.current
		]
	);
};

export default PlaybackProvider;
