import { Menu }                                     from "@mantine/core";
import { Slider }                                   from "@mantine/core";
import { usePrevious }                              from "@mantine/hooks";
import dynamic                                      from "next/dynamic";
import { useRouter }                                from "next/router";
import { useCallback, useEffect, useRef, useState } from "react";

import { useGetDeviceType } from "_common/hooks/global";
import { isPageRoute }      from "_common/utils";
import zIndex               from "_common/zIndex";
import Icon                 from "_components/atoms/icon";
import Render               from "_components/atoms/render";
import Typography           from "_components/atoms/typography";
import { rooterMediaQuery } from "_styles/theme/mediaQuery";

import useLiveTimer    from "../hooks/useLiveTimer";
import useMobileView   from "../hooks/useMobileView";
import usePlayback     from "../hooks/usePlayback";
import {
	useGetCurrPlaybackSpeed,
	useGetCurrPogress,
	useGetCurrQuality,
	useGetFullscreen,
	useGetIsMute,
	useGetIsPaused,
	useGetQualities,
	useGetTimeSinceLive
} from "../states";
import { formatTime, formattedVideoDuration } from "../utils";

import Fullscreen            from "./buttons/fullscreen";
import PlayPause             from "./buttons/playPause";
import Settings              from "./buttons/settings";
import Volume                from "./buttons/volume";
import FadeInOut             from "./fadeInOut";
import PlaybackSpeedSelector from "./playbackSpeed";
import QualitySelector       from "./qualitySelector";
import SeekBar               from "./seekBar";
import useStyles             from "./styles.js";

const SettingsMenu     = dynamic ( () => import ( "./settingsMenu" ) );
const LiveComponent    = dynamic ( () => import ( "./liveBar" ) );
const VODSeekbar       = dynamic ( () => import ( "./vodSeekbar" ) );
const KeyboardControls = dynamic ( () => import ( "./keyboardControls" ), { ssr: false } );
const TheatreMode      = dynamic ( () => import ( "./buttons/theatreMode" ), { ssr: false } );

const MenuHeader = ( { backToSettings, title } ) => {

	return (
		<>
			<div
				onClick = { backToSettings }
				style   = { {
					display      : "flex",
					alignItems   : "center",
					marginBottom : "5px",
					cursor       : "pointer"
				} }
			>
				<Icon
					sameInBothTheme
					name  = "caret"
					size  = "25px"
					style = { { margin: "5px", transform: " scaleX(-1)" } }
				/>

				<Typography
					size   = "sm"
					title  = { title }
					weight = "500"
				/>
			</div>

			<Menu.Divider />
		</>
	);
};
const Controls   = ( {
	noControls,
	isLive,
	liveViews,
	startedAt,
	isStreamer,
	seekFeature,
	// isSeekBarDisabled = false,
	// onReady = () => {},
	customControls = () => { },
	disableTheatreMode,
	disableControlSettings,
	isReel,
	disableFullScreen,
	duration,
	currentTime,
	setCurrentTime,
	page,
	fromVideoCard,
	fadeReelControls,
	handleCancelPlayNext,
	isControlsOpen,
	showControlsOnVideoEnd
	// isVideoPlaying
	// handleVideoClick
} ) => {

	const {
		toggleMute,
		setPlaybackSpeed,
		setPlaybackSpeedOnVideo,
		setQuality,
		onSeek,
		onSeekVideo
		// isEnded = false
	} = usePlayback ();

	const currProgress           = useGetCurrPogress ();
	const { classes, theme, cx } = useStyles ( {}, { name: "videoPlayerControls" } );
	const timeSinceLive          = useGetTimeSinceLive ();
	const isMute                 = useGetIsMute ();
	const isPaused               = useGetIsPaused ();
	const fullscreen             = useGetFullscreen ();
	const qualities              = useGetQualities ();
	const currQuality            = useGetCurrQuality ();
	const currPlaybackSpeed      = useGetCurrPlaybackSpeed ();
	const { isMobileView }       = useMobileView ();
	const ref                    = useRef ( 0 );

	const [ settingsMenu, setOpenSettingsMenu ]           = useState ( false );
	const [ qualityMenu, setOpenQualityMenu ]             = useState ( false );
	const [ playbackSpeedMenu, setOpenPlaybackSpeedMenu ] = useState ( false );
	const [ paused, setPaused ]                           = useState ( null );

	const router       = useRouter ();
	const isStreamPage = isPageRoute ( { router, page: "stream" } );
	const isReelsPage  = isPageRoute ( { router, page: "reels" } );
	const isVideoPage  = isPageRoute ( { router, page: "video" } );
	const prevState    = usePrevious ( paused );

	useEffect ( () => {
		if ( ref.current > 0 ) {
			setPaused ( isPaused );
		}
		ref.current += 1;

		return () => {
			setPaused ( null );
		};
	}, [ isPaused ] );

	const isStudioPage = page === "studio";

	const onSeekHandler = useCallback ( val => {
		if ( isLive )
			onSeek ( val );
		else
			onSeekVideo ( val );

		setCurrentTime ( val );
		handleCancelPlayNext (); // passed as a prop from videos
	}, [ isLive ] );

	const setSettings = value => {
		// setOpenSettingsMenu ( false );

		if ( value === "quality" ) {
			setOpenPlaybackSpeedMenu ( false );
			setOpenQualityMenu ( true );
		}

		if ( value === "speed" ) {
			setOpenQualityMenu ( false );
			setOpenPlaybackSpeedMenu ( true );
		}
	};

	const __setQuality = value => {
		setQuality ( value );
		setOpenQualityMenu ( false );
	};

	const __setPlaybackSpeed = useCallback ( val => {
		if ( isLive )
			setPlaybackSpeed ( val );
		else
			setPlaybackSpeedOnVideo ( val );

		setOpenPlaybackSpeedMenu ( false );
	}, [ isLive ] );

	const backToSettings = () => {
		setOpenPlaybackSpeedMenu ( false );
		setOpenQualityMenu ( false );
		setOpenSettingsMenu ( true );
	};

	const closeSettings = () => {
		// const closestParentButton = evt.target.closest ( "button" );

		// if ( closestParentButton?.id === "player-control-settings" )
		// return;

		setOpenSettingsMenu ( false );
		setOpenPlaybackSpeedMenu ( false );
		setOpenQualityMenu ( false );
	};

	const deviceType = useGetDeviceType ();
	const isDesktop  = deviceType === "desktop";
	const isMobile   = deviceType === "mobile";

	if ( fromVideoCard ) {
		return customControls ( { fullscreen, toggleMute, isMute } );
	}

	if ( noControls )
		return null;

	const noKeyboardControls = !isStreamPage && !isVideoPage && !isReelsPage ? true : false;

	if ( isReel ) {
		return (
			<FadeInOut
				mountOnEnter
				inProp = { fadeReelControls }
			>

				<Render condition = { !isMobileView && !noKeyboardControls }>
					<KeyboardControls isLive = { isLive } />
				</Render>

				<Icon
					sameInBothTheme
					className    = { classes.reelMuteIcon }
					name         = { isMute ? "volumeOff" : "volumeOn" }
					onMouseDown  = { e => {
						if ( isMobile ) return;
						e.stopPropagation ();
					} }
					onMouseUp    = { e => {
						if ( isMobile ) return;
						e.stopPropagation ();
						toggleMute ();
					} }
					onTouchEnd   = { e => {
						if ( isDesktop ) return;
						e.stopPropagation ();
						toggleMute ();
					} }
					onTouchStart = { e => {
						if ( isDesktop ) return;
						e.stopPropagation ();
					} }
				/>

				<div className = { classes.playPauseIconWrapper }>

					<Render condition = { paused !== null && paused !== true && prevState === true }>
						<Icon
							sameInBothTheme
							className = { cx ( classes.playIcon, classes.icon ) }
							// data-show = { paused !== true ? true : undefined }
							data-show = { true }
							id        = "pause-btn"
							name      = { "play-button" }
						/>
					</Render>

					<Icon
						sameInBothTheme
						className = { cx ( classes.pauseIcon, classes.icon ) }
						data-show = { paused ? paused : undefined }
						id        = "play-btn"
						name      = { "reelPauseIcon" }
					/>

				</div>

				<div
					style = { {
						position : "absolute",
						zIndex   : 99,
						left     : "0",
						right    : "0",
						bottom   : "0",
						top      : "unset",

						[rooterMediaQuery.mobile]: {
							top: "calc(100dvh - 2px)"
						}
					} }
				>
					<Slider
						max      = { duration }
						onChange = { onSeekHandler }
						styles   = { {
							trackContainer: {
								height: "unset"
							},
							root: {
								width: "100%"
							},
							bar: {
								transition      : "all 0.05s ease-in-out",
								borderRadius    : isMobile ? "0" : currentTime >= duration ? "0 0 2rem 2rem" : "0 0 0 1rem",
								backgroundColor : theme.other.paletteNew.darkModeWhite80
							},
							track: {
								height    : isMobile ? "2px" : "2px",
								":before" : {
									borderRadius    : isMobile ? "0" : "0 0 2rem 2rem",
									backgroundColor : theme.other.paletteNew.darkModeWhite20
								}
							},
							thumb: {
								display: "none"
							}
						} }
						value    = { currentTime }
					/>
				</div>

			</FadeInOut>

		);
	}

	return (
		<>
			<Render condition = { typeof customControls !== "undefined" }>
				<FadeInOut
					mountOnEnter
					inProp = { isControlsOpen || showControlsOnVideoEnd }
				>

					{customControls ( { fullscreen } )}
				</FadeInOut>
			</Render>

			<Render condition = { !isMobileView && !noKeyboardControls }>
				<KeyboardControls isLive = { isLive } />
			</Render>

			<FadeInOut
				mountOnEnter
				className = "player-controls-container"
				inProp    = { isPaused || isControlsOpen || showControlsOnVideoEnd }
			>

				{/* { isMobileView ? null : (
					<SettingsMenu
						isLive   = { isLive }
						menuOpen = { settingsMenu }
						onClose  = { closeSettings }
						onSelect = { setSettings }
						quality  = { currQuality }
						speed    = { currPlaybackSpeed === 1 ? "Normal" : currPlaybackSpeed }
					/>
				)} */}

				{/* {
					qualities?.length > 0 ? (
						<QualitySelector
							currQuality = { currQuality }
							goBack      = { backToSettings }
							menuOpen    = { qualityMenu }
							onClose     = { closeSettings }
							qualities   = { qualities }
							setQuality  = { __setQuality }
						/>
					) : null
				} */}

				{/* <PlaybackSpeedSelector
					currSpeed = { currPlaybackSpeed }
					goBack    = { backToSettings }
					menuOpen  = { playbackSpeedMenu }
					onClose   = { closeSettings }
					setSpeed  = { __setPlaybackSpeed }
				/> */}

				<Render condition = { isLive && !isStudioPage && !seekFeature }>
					<LiveComponent
						isLive        = { currProgress < 100 ? false : true }
						isMobileView  = { isMobileView }
						startedAt     = { startedAt }
						timeSinceLive = { formatTime ( timeSinceLive ) }
						viewCount     = { liveViews }
					/>
				</Render>

				<Render condition = { isLive && seekFeature }>

					<VODSeekbar
						isLive      = { isLive }
						liveViews   = { liveViews }
						seekFeature = { seekFeature }
						startedAt   = { startedAt }
					/>
				</Render>

				<Render condition = { !isLive && duration }>

					<SeekBar
						currentTime   = { currentTime }
						duration      = { duration }
						onSeek        = { onSeekHandler }
						stopAnimation = { isPaused }
					/>
				</Render>

				<div className = "player-controls-wrapper">
					<div className = "player-controls-btn-container flex alignItemsCenter">
						<PlayPause />

						<div className = "flex alignItemsCenter">
							<Volume />
						</div>

						<Render condition = { isStudioPage && isLive }>
							<LiveTimer startedAt = { startedAt } />
						</Render>

						<Render condition = { !isLive }>
							<VideoTimer
								currTime = { currentTime }
								duration = { duration }
							/>

						</Render>
					</div>

					<div className = "player-controls-btn-container">
						<Render condition = { !disableTheatreMode && !isStreamer && !fullscreen && !isMobileView }>
							<TheatreMode />
						</Render>

						<Render condition = { !disableControlSettings && !isMobileView }>
							<Menu
								closeOnClickOutside
								classNames       = { {
									dropdown : classes.container,
									item     : classes.item,
									divider  : classes.divider
								} }
								closeOnEscape    = { true }
								closeOnItemClick = { false }
								onClose          = { closeSettings }
								opened           = { settingsMenu || playbackSpeedMenu || qualityMenu }
								position         = "top-end"
								transitionProps  = { { duration: 150, transition: "slide-up" } }
								withinPortal     = { false }
								zIndex           = { zIndex.player.settings }
							>
								<Menu.Target>
									<Settings
										setOpenPlaybackSpeedMenu = { setOpenPlaybackSpeedMenu }
										setOpenQualityMenu       = { setOpenQualityMenu }
										setOpenSettingsMenu      = { setOpenSettingsMenu }
										settingsMenu             = { settingsMenu }
									/>
								</Menu.Target>

								<Menu.Dropdown>

									{playbackSpeedMenu ? (
										<MenuHeader backToSettings = { backToSettings }
											title                     = { "Playback Speed" }
										/>
									) : qualityMenu ? (
										<MenuHeader backToSettings = { backToSettings }
											title                     = { "Stream Quality" }
										/>
									) : null}

									{
										isMobileView ? null : playbackSpeedMenu ? (
											<PlaybackSpeedSelector
												currSpeed = { currPlaybackSpeed }
												goBack    = { backToSettings }
												// menuOpen = { playbackSpeedMenu }
												onClose   = { closeSettings }
												setSpeed  = { __setPlaybackSpeed }
											/>
										) : qualityMenu ? (
											qualities?.length > 0 ? (
												<QualitySelector
													currQuality  = { currQuality }
													goBack       = { backToSettings }
													isStreamPage = { isStreamPage }
													onClose      = { closeSettings }
													qualities    = { qualities }
													setQuality   = { __setQuality }
												/>
											) : null
										) : (
											<SettingsMenu
												isLive   = { isLive }
												// menuOpen = { settingsMenu }
												onClose  = { closeSettings }
												onSelect = { setSettings }
												quality  = { currQuality }
												speed    = { currPlaybackSpeed === 1 ? "Normal" : currPlaybackSpeed }
											/>
										)
									}

								</Menu.Dropdown>
							</Menu>
						</Render>

						<Render condition = { !disableFullScreen }>
							<Fullscreen />
						</Render>
					</div>
				</div>
			</FadeInOut>
		</>
	);
};

export default Controls;

const VideoTimer = ( { duration, currTime } ) => {

	return (
		<div className = "video-timer-container">
			{`${ currTime ? formattedVideoDuration ( currTime ) : "00:00" } / ${ formattedVideoDuration ( duration ) }`}
		</div>
	);
};

const LiveTimer = ( { startedAt } ) => {
	const { liveTime } = useLiveTimer ( { startedAt } );

	return (
		<div className = "liveTimeContainer">
			<span className = "liveTimeText">
				{liveTime}
			</span>
		</div>
	);
};
