import {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";

import { useGetLoginPopup } from "_common/hooks/global";
import { isAppleDevice }    from "_common/utils";

import {
	useGetCurrentTime,
	useGetDuration,
	useGetQualities,
	useGetVolPercent,
	useSetCurrPlaybackSpeed,
	useSetCurrQuality,
	useSetCurrentTime,
	useSetDuration,
	useSetIsMute,
	useSetIsPaused,
	useSetIsPlaying,
	useSetQualities,
	useSetVolPercent,
	useSetVolPercentLocal
} from "../states";

const usePlayer = () => {
	const [ ref, setRef ]       = useState ( undefined );
	const playerRef             = useRef ( null );
	const volumeRef             = useRef ( null );
	const setIsMute             = useSetIsMute ();
	const setIsPaused           = useSetIsPaused ();
	const setQualities          = useSetQualities ();
	const loginPopup            = useGetLoginPopup ();
	const qualities             = useGetQualities ();
	const setCurrQuality        = useSetCurrQuality ();
	const setVolumePercentLocal = useSetVolPercentLocal ();
	const setVolumePercent      = useSetVolPercent ();
	const volumePercent         = useGetVolPercent ();
	const setCurrPlaybackSpeed  = useSetCurrPlaybackSpeed ();
	const setDuration           = useSetDuration ();
	const duration              = useGetDuration ();
	const setCurrentTime        = useSetCurrentTime ();
	const currentTime           = useGetCurrentTime ();

	const updateTimer = useCallback ( () => {
		const currTime = playerRef.current.currentTime ();

		setCurrentTime ( currTime );
	}, [] );

	useEffect ( () => {
		if ( loginPopup ) {
			pause ();
		}
	}, [ loginPopup ] );

	useEffect ( () => {
		if ( !ref || ref?.isDisposed_ )
			return;

		playerRef.current = ref;

		playerRef.current.volume ( parseFloat ( volumePercent / 100 ).toFixed ( 2 ) );
	}, [ ref ] );

	useEffect ( () => {
		volumeRef.current = volumePercent;
		setVolumePercentLocal ( volumePercent );

		if ( !playerRef?.current || playerRef?.current?.isDisposed_ ) return;

		playerRef.current.volume ( parseFloat ( volumePercent / 100 ).toFixed ( 2 ) );
	}, [ volumePercent ] );

	const isReady = useCallback ( () => {
		if ( !playerRef?.current || playerRef?.current?.isDisposed_ ) return false;

		return true;
	}, [ playerRef ] );

	const enterFullscreen = useCallback ( () => {
		if ( !playerRef?.current || playerRef?.current?.isDisposed_ ) return;

		return playerRef.current?.requestFullscreen ();
	}, [ playerRef ] );

	const exitFullscreen = useCallback ( () => {
		if ( !playerRef?.current || playerRef?.current?.isDisposed_ ) return;

		return playerRef.current?.exitFullscreen ();
	}, [ playerRef ] );

	const getDuration = useCallback ( () => {
		if ( !playerRef?.current || playerRef?.current?.isDisposed_ ) return 0;

		return playerRef.current?.tech ()?.vhs?.mediaSource?.duration;
	}, [ playerRef ] );

	const seekToPos = useCallback (
		seekPosition => {
			if ( !playerRef?.current || playerRef?.current?.isDisposed_ )
				return;

			playerRef?.current?.currentTime ( seekPosition );
		},
		[ playerRef ]
	);

	const getPosition = useCallback (
		() => {
			if ( !playerRef?.current || playerRef?.current?.isDisposed_ ) return 0;

			return playerRef?.current.currentTime ();
		},
		[ playerRef ]
	);

	// PlayerState event callback
	const play = useCallback ( () => {
		if ( !playerRef?.current || playerRef?.current?.isDisposed_ ) return;

		// if ( hasError ) {
		// setIsLoading ( true );
		// playerRef.current.load ( urlToLoad );
		// }

		if ( playerRef.current?.paused () ) {
			playerRef.current.play ();
			setIsPaused ( false );
		}
	}, [ playerRef ] );

	const pause = useCallback ( () => {
		if ( !playerRef?.current || playerRef?.current?.isDisposed_ ) return;

		if ( !playerRef.current?.paused () ) {
			playerRef.current.pause ();
			setIsPaused ( true );
		}
	}, [] );

	const onVolumeChange = useCallback ( val => {
		if ( !playerRef?.current || playerRef?.current?.isDisposed_ ) return;

		if ( parseInt ( val ) === 0 || playerRef?.current?.muted () )
			toggleMute ();

		setVolumePercent ( val );
		setVolumePercentLocal ( val );
	}, [ playerRef ] );

	const seekByNSeconds = useCallback ( sec => {
		if ( !playerRef?.current || playerRef?.current?.isDisposed_ ) return;

		const currentTime = playerRef.current.currentTime ();

		const seektime = parseInt ( currentTime ) + sec;

		if ( seektime < 0 ) {
			seekToPos ( 0 );
		} else if ( seektime > getDuration () ) {
			seekToPos ( getDuration () );
		} else {
			seekToPos ( seektime );
		}
		play ();
		setIsPaused ( false );
		// setIsEnded ( false );

	}, [ playerRef ] );

	const onSeek = useCallback ( val => {
		// if ( isEnded ) {
		// }
		seekToPos ( val );

		// setIsEnded ( false );
		play ();
	}, [] );

	const togglePlayback = useCallback ( () => {
		if ( !playerRef?.current ) return;

		// this is prevent player from affecting the live stream while playing ads
		if ( playerRef?.current?.rooterCustoms?.isPlayingAd )
			return;

		const paused = playerRef?.current?.paused ();

		setIsPaused ( !paused );

		if ( !paused ) {
			pause ();
		} else {
			play ();
		}
	}, [ playerRef ] );

	const toggleMute = useCallback ( () => {
		if ( !playerRef?.current || playerRef?.current?.isDisposed_ ) return;

		const value = playerRef.current.muted ();

		// if muted then unmute and set the volume from jotai
		if ( value ) {
			setVolumePercentLocal ( volumeRef?.current );
			playerRef.current.volume ( parseFloat ( volumeRef?.current / 100 ).toFixed ( 2 ) );
		}

		playerRef.current.muted ( !value );
		setIsMute ( !value );
	}, [ playerRef ] );

	const setQualityOptions = useCallback ( () => {
		const isApple = isAppleDevice ( navigator.userAgent );

		if ( !playerRef?.current || playerRef?.current?.isDisposed_ || isApple || qualities.length )
			return;

		const resolutions = playerRef.current.tech ()?.vhs?.representations () || [];

		resolutions.sort ( ( a, b ) => {
			if ( a.height < b.height )
				return 1;

			return -1;
		} );

		setQualities ( resolutions.map ( item => {
			return item.height;
		} ) );
	}, [ qualities ] );

	const enable720pQuality = useCallback ( resolutions => {
		if ( !resolutions?.length ) return;

		resolutions.sort ( ( a, b ) => {
			if ( a.height < b.height )
				return -1;

			return 1;
		} );

		resolutions.forEach ( quality => {

			if ( quality.height === 720 )
				quality.enabled ( true );
			else
				quality.enabled ( false );

		} );

	}, [] );

	const setQuality = useCallback ( value => {
		if ( !playerRef?.current || playerRef?.current?.isDisposed_ ) return;

		/*
           * To enable a certain resolution...you need to enable that and disable others...for manual selection
           * for auto...enable all
           *
          */

		const resolutions = playerRef.current.tech ()?.vhs?.representations () || [];

		const videoIndex = {};

		resolutions.forEach ( ( item, index ) => {
			videoIndex[item.height] = index;
		} );

		if ( !resolutions || !resolutions.length )
			return;

		if ( playerRef.current?.rooterCustoms?.restrictQuality ) {
			switch ( value ) {
				case "auto":
					resolutions.forEach ( quality => {
						if ( quality.height === 1080 )
							quality.enabled ( false );
						else
							quality.enabled ( true );
					} );
					break;

				case 1080:
					enable720pQuality ( resolutions );
					break;

				default:
					resolutions.forEach ( ( quality, index ) => {
						if ( index === videoIndex[value] )
							quality.enabled ( true );
						else
							quality.enabled ( false );
					} );
					break;
			}
		} else {
			if ( value === "auto" )
				resolutions.forEach ( quality => quality.enabled ( true ) );
			else {
				resolutions.forEach ( ( quality, index ) => {
					if ( index === videoIndex[value] )
						quality.enabled ( true );
					else
						quality.enabled ( false );
				} );
			}
		}

		setCurrQuality ( value );
	}, [] );

	const setPlaybackSpeed = useCallback ( value => {
		if ( !playerRef?.current || playerRef?.current?.isDisposed_ ) return;

		playerRef.current.playbackRate ( value );
		setCurrPlaybackSpeed ( value );

	}, [ playerRef ] );

	// const reset = useCallback ( () => {
	// setIsReady ( false );
	// setIsLoading ( false );
	// }, [ ] );

	// useEffect ( () => {
	// if ( hasError ) reset ();
	// }, [ hasError, reset ] );

	return useMemo (
		() => ( {
			isReady,
			setRef,
			enterFullscreen,
			exitFullscreen,
			getDuration,
			getPosition,
			seekToPos,
			play,
			pause,
			toggleMute,
			setQualityOptions,
			setQuality,
			setPlaybackSpeed,
			togglePlayback,
			// reset,
			onSeek,
			seekByNSeconds,
			onVolumeChange,
			duration,
			setDuration,
			currentTime,
			setCurrentTime

		} ),
		[
			isReady,
			setRef,
			enterFullscreen,
			exitFullscreen,
			getDuration,
			getPosition,
			seekToPos,
			play,
			pause,
			toggleMute,
			setQualityOptions,
			setQuality,
			setPlaybackSpeed,
			togglePlayback,
			// reset,
			onSeek,
			seekByNSeconds,
			onVolumeChange,
			duration,
			setDuration,
			currentTime,
			setCurrentTime

		]
	);
};

export default usePlayer;
