import { useEffect, useState } from "react";

import { getVideoFormatTime } from "_common/utils/broadcast";

const useLiveTimer = ( { startedAt } ) => {
	const [ liveTime, setLiveTime ] = useState ();
	let timer;

	useEffect ( () => {
		getLiveTimer ();

		return () => {
			if ( timer )
				clearInterval ( timer );
		};
	}, [ startedAt ] );

	const getLiveTimer = () => {
		if ( !startedAt )
			return;

		const setTime = () => {
			setLiveTime ( getVideoFormatTime ( startedAt ) );
		};

		timer = setInterval ( () => setTime (), 1000 );
	};

	return { liveTime };
};

export default useLiveTimer;