import { liveConfig, videoConfig, recordedConfig } from "./config";
import { formatTime }                              from "./utils";

const addPreRoll = ( tag, index ) => {
	return `<vmap:AdBreak timeOffset="start" breakType="linear" breakId="preroll">
            <vmap:AdSource id="preroll-ad-${ index }" allowMultipleAds="false" followRedirects="true">
               <vmap:AdTagURI templateType="vast3">
                  <![CDATA[${ tag }]]>
               </vmap:AdTagURI>
            </vmap:AdSource>
          </vmap:AdBreak>`;
};

const addMidRoll = ( tag, offset, index ) => {
	return `<vmap:AdBreak timeOffset="${ formatTime ( offset ) }" breakType="linear" breakId="midroll-${ index }">
            <vmap:AdSource id="midroll-${ index }-ad-1" allowMultipleAds="false" followRedirects="true">
              <vmap:AdTagURI templateType="vast3">
                <![CDATA[${ tag }]]>
              </vmap:AdTagURI>
            </vmap:AdSource>
          </vmap:AdBreak>`;
};

const addPostRoll = ( tag, index ) => {
	return `<vmap:AdBreak timeOffset="end" breakType="linear" breakId="postroll">
            <vmap:AdSource id="postroll-ad-${ index }" allowMultipleAds="false" followRedirects="true">
              <vmap:AdTagURI templateType="vast3">
                <![CDATA[${ tag }]]>
              </vmap:AdTagURI>
            </vmap:AdSource>
          </vmap:AdBreak>`;
};

const createListForRecorded = ( isPro, readTime ) => {
	let adConfig = "";
	const config = recordedConfig[isPro ? "pro" : "standard"];

	if ( config.dynamicConfigModel.preRollEnabled && config.dynamicConfigModel.preRollUris.length ) {
		for ( let i = 0; i < config.dynamicConfigModel.preRollCount; i ++ ) {
			adConfig = adConfig.concat ( addPreRoll ( config.dynamicConfigModel.preRollUris[0], i + 1 ) );
		}
	}

	// need to calculate read time by end - start time

	if ( config.dynamicConfigModel.midRollEnabled && config.dynamicConfigModel.midRollUris.length ) {
		let count = ( readTime / 60 ) / config.dynamicConfigModel.recordedStreamAdInsertionInterval;

		for ( let i = 0; i < count; i ++ ) {
			let offset = config.dynamicConfigModel.recordedStreamAdInsertionInterval * 60 * ( i + 1 );

			adConfig = adConfig.concat ( addMidRoll ( config.dynamicConfigModel.midRollUris[0], offset, i + 1 ) );
		}
	}

	if ( config.dynamicConfigModel.postRollEnabled && config.dynamicConfigModel.postRollUris.length ) {
		for ( let i = 0; i < config.dynamicConfigModel.postRollCount; i ++ ) {
			adConfig = adConfig.concat ( addPostRoll ( config.dynamicConfigModel.postRollUris[0], i + 1 ) );
		}
	}

	return adConfig;
};

const createListForVideos = ( isPro, readTime ) => {
	let adConfig = "";
	const config = videoConfig[isPro ? "pro" : "standard"];

	if ( config.dynamicConfigModel.preRollEnabled && config.dynamicConfigModel.preRollUris.length ) {
		for ( let i = 0; i < config.dynamicConfigModel.preRollCount; i ++ ) {
			adConfig = adConfig.concat ( addPreRoll ( config.dynamicConfigModel.preRollUris[0], i + 1 ) );
		}
	}

	if ( config.dynamicConfigModel.midRollEnabled && config.dynamicConfigModel.midRollUris.length ) {

		// Check in which bracket does the video lie to get the config
		let options = config.dynamicConfigModel.midRollConfig;
		let __config;

		options.forEach ( item => {
			if ( ( readTime / 60 ) <= item.max && ( readTime / 60 ) > item.min )
				__config = item;
		} );

		for ( let i = 0; i < __config.count; i ++ ) {
			let offset = ( readTime / ( __config.count + 1 ) ) * ( i + 1 );

			adConfig = adConfig.concat ( addMidRoll ( config.dynamicConfigModel.midRollUris[0], offset, i + 1 ) );
		}
	}

	if ( config.dynamicConfigModel.postRollEnabled && config.dynamicConfigModel.postRollUris.length ) {
		for ( let i = 0; i < config.dynamicConfigModel.postRollCount; i ++ ) {
			adConfig = adConfig.concat ( addPostRoll ( config.dynamicConfigModel.postRollUris[0], i + 1 ) );
		}
	}

	return adConfig;
};

const createListForLive = isPro => {
	let adConfig = "";
	const config = liveConfig[isPro ? "pro" : "standard"];

	if ( config.dynamicConfigModel.preRollEnabled && config.dynamicConfigModel.preRollUris.length ) {
		for ( let i = 0; i < config.dynamicConfigModel.preRollCount; i ++ ) {
			adConfig = adConfig.concat ( addPreRoll ( config.dynamicConfigModel.preRollUris[0], i + 1 ) );
		}
	}

	return adConfig;
};

const createVmap = ( readTime, isPro, type ) => {
	let adConfig = "";

	if ( type === "live" )
		adConfig = createListForLive ( isPro );
	else if ( type === "video" )
		adConfig = createListForVideos ( isPro, readTime );
	else if ( type === "recorded" )
		adConfig = createListForRecorded ( isPro, readTime );
	else
		adConfig = "";

	return `<?xml version="1.0" encoding="UTF-8"?>
              <vmap:VMAP xmlns:vmap="http://www.iab.net/videosuite/vmap" version="1.0">
                ${ adConfig }
              </vmap:VMAP>`;
};

export default createVmap;
