import { useEffect, useMemo, useState } from "react";
import { useRef, useCallback }          from "react";

import { MOBILE_BREAKPOINT } from "../constants";

const useMobileView = () => {
	const [ isMobileView, setIsMobileView ] = useState ( undefined );

	useEffect ( () => {
		const handleWindowResize = () => {
			setIsMobileView ( window.innerWidth <= MOBILE_BREAKPOINT );
		};

		handleWindowResize ();
		window.addEventListener ( "resize", handleWindowResize );

		return () => window.removeEventListener ( "resize", handleWindowResize );
	}, [] );

	return useMemo ( () => ( { isMobileView } ), [ isMobileView ] );
};

export const useMobileClickHandler = () => {
	const [ isControlsOpen, setIsControlsOpen ] = useState ( false );
	const { isMobileView }                      = useMobileView ();
	const timeoutId                             = useRef ( null );

	const clearControlsTimeout = useCallback ( () => {
		clearTimeout ( timeoutId.current );
		timeoutId.current = null;
	}, [] );

	const resetControlsTimeout = useCallback ( () => {
		clearControlsTimeout ();
		timeoutId.current = setTimeout ( () => {
			setIsControlsOpen ( false );
			timeoutId.current = null;
		}, 3000 );
	}, [ clearControlsTimeout ] );

	// Mobile controls toggling logic
	useEffect ( () => {
		const mobileClickHandler = () => {
			if ( !timeoutId.current ) {
				setIsControlsOpen ( true );
				resetControlsTimeout ();
			}
		};

		if ( isMobileView ) {
			mobileClickHandler ();
			document.addEventListener ( "pointerdown", mobileClickHandler );
		}

		return () => {
			document.removeEventListener ( "pointerdown", mobileClickHandler );
		};
	}, [ clearControlsTimeout, isMobileView, resetControlsTimeout ] );

	return {
		isControlsOpen
	};
};

export default useMobileView;
