import { createStyles } from "@mantine/emotion";

export default createStyles ( theme => {
	return {
		container: {
			borderRadius    : "10px",
			width           : "300px",
			maxWidth        : 360,
			backgroundColor : theme.other.darkMode ? theme.other.paletteNew.darkModeInclinedBlack2 : theme.other.paletteNew.secondaryWhite,
			color           : theme.other.colors.text.primary,
			position        : "absolute",
			right           : "65px",
			bottom          : "60px",
			outline         : "1px solid green"
		},
		caretIcon: {
			height : "3rem",
			width  : "3rem"
		},
		proButton: {
			background : theme.other.paletteNew.proGradient,
			padding    : "0.25rem 1rem"
		}
	};
} );

