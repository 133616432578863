import Controls from "./presentation";

const Component = ( {
	// noBottomSec,
	currentTime,
	customControls,
	disableControlSettings,
	disableFullScreen,
	disableTheatreMode,
	duration,
	fromVideoCard,
	handleCancelPlayNext,
	isControlsOpen,
	isLive,
	isReel,
	isStreamer,
	liveViews,
	noControls,
	onClick,
	onMute,
	onReady,
	page,
	seekFeature,
	fadeReelControls,
	setCurrentTime,
	showControlsOnVideoEnd = false, // from videos,
	startedAt
} ) => {

	return (
		<Controls
			currentTime            = { currentTime }
			customControls         = { customControls }
			disableControlSettings = { disableControlSettings }
			disableFullScreen      = { disableFullScreen }
			disableTheatreMode     = { disableTheatreMode }
			duration               = { duration }
			fadeReelControls       = { fadeReelControls }
			fromVideoCard          = { fromVideoCard }
			handleCancelPlayNext   = { handleCancelPlayNext }
			handleVideoClick       = { () => { } } // isLive ? handleLiveVideoClick : handleEndedVideoClick }
			isControlsOpen         = { isControlsOpen }
			isLive                 = { isLive }
			isReel                 = { isReel }
			isStreamer             = { isStreamer }
			liveViews              = { liveViews }
			noControls             = { noControls }
			onClick                = { onClick }
			onMute                 = { onMute }
			onReady                = { onReady }
			page                   = { page }
			seekFeature            = { seekFeature }
			setCurrentTime         = { setCurrentTime }
			showControlsOnVideoEnd = { showControlsOnVideoEnd }
			startedAt              = { startedAt }
		/>
	);
};

export default Component;
