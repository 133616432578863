
const header = {
	root: 1115
};

const studio = {
	boostStream: 1200
};

const notification = {
	menu: 1200
};

const player = {
	settings: 1200
};
const zIndex = {
	header,
	studio,
	notification,
	player
};

export default zIndex;
