export function formatTime ( seconds ) {
	// Calculate the hours, minutes, and seconds
	const hours        = Math.floor ( seconds / 3600 );
	const minutes      = Math.floor ( ( seconds % 3600 ) / 60 );
	const secs         = Math.floor ( seconds % 60 );
	const milliseconds = Math.floor ( ( seconds - Math.floor ( seconds ) ) * 1000 );

	// Pad with leading zeros
	const paddedHours        = String ( hours ).padStart ( 2, "0" );
	const paddedMinutes      = String ( minutes ).padStart ( 2, "0" );
	const paddedSeconds      = String ( secs ).padStart ( 2, "0" );
	const paddedMilliseconds = String ( milliseconds ).padStart ( 3, "0" );

	// Return formatted time
	return `${ paddedHours }:${ paddedMinutes }:${ paddedSeconds }.${ paddedMilliseconds }`;
}
