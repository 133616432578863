import { useState, memo } from "react";

import CustomSlider from "../customSlider";

import useStyles from "./styles";

const VolumeSlider = ( { onChange, value, show, clearTimer, hideSlider } ) => {
	const [ showSlider, setShowSlider ] = useState ( false );
	const { classes }                   = useStyles ( { show: showSlider || show } );

	const mouseEnterHandler = () => {
		clearTimer ();
		setShowSlider ( true );
	};

	const mouseLeaveHandler = () => {
		setShowSlider ( false );
		hideSlider ();
	};

	return (
		<CustomSlider
			disableAmination
			containerClass = { classes.containerClass }
			onChange       = { onChange }
			onMouseEnter   = { mouseEnterHandler }
			onMouseLeave   = { mouseLeaveHandler }
			percent        = { value }
			value          = { value }
		/>
	);
};

export default memo ( VolumeSlider );
