import { useTimeout }                  from "@mantine/hooks";
import { memo, useCallback, useState } from "react";

import Icon    from "_components/atoms/icon";
import ToolTip from "_components/atoms/tooltip";

import usePlayback       from "../../hooks/usePlayback";
import {
	useGetHasError,
	useGetIsMute,
	useGetVolPercentLocal
} from "../../states";
import VolumeSlider from "../volumeSlider";

const Controls = ( ) => {
	const {
		toggleMute,
		onVolumeChange
	} = usePlayback ();

	const hasError                                  = useGetHasError ();
	const isMute                                    = useGetIsMute ();
	const volumePercent                             = useGetVolPercentLocal ();
	const [ showVolumeSlider, setShowVolumeSlider ] = useState ( false );
	const { start: startTimer, clear: clearTimer }  = useTimeout ( () => setShowVolumeSlider ( false ), 1000 );

	const onPointerDownMuteUnmuteHandler = useCallback (
		() => {
			if ( hasError ) return;

			toggleMute ();
			setShowVolumeSlider ( true );
		},
		[
			hasError
		]
	);

	const volumeChangeHandler = val => {
		onVolumeChange ( val );
	};

	const hideVolumeSlider = () => {
		startTimer ( );
	};

	return (
		<div style = { {
			display    : "flex",
			alignItems : "center"
		} }
		>
			<ToolTip
				arrow
				disableLightMode
				placement = "top"
				title     = { isMute ? "Unmute (m)" : "Mute (m)" }
			>
				<button
					aria-disabled = { hasError }
					className     = "player-controls-btn zIndexTen"
					onMouseEnter  = { () => setShowVolumeSlider ( true ) }
					onMouseLeave  = { hideVolumeSlider }
					onPointerDown = { onPointerDownMuteUnmuteHandler }
				>
					<Icon sameInBothTheme
						name      = { isMute ? "volumeOffIcon" : "volumeOnIcon" }
					/>
				</button>
			</ToolTip>

			<VolumeSlider
				clearTimer = { clearTimer }
				hideSlider = { () => setShowVolumeSlider ( false ) }
				onChange   = { volumeChangeHandler }
				show       = { showVolumeSlider }
				value      = { isMute ? 0 : volumePercent }
			/>

		</div>
	);
};

export default memo ( Controls );
